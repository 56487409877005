/* istanbul ignore file */

import {createStylesParam, IStyleParam, IStyleParamValuesByType, StyleParamType, wixFontParam} from '@wix/tpa-settings';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import {
  DEFAULT_AUTO_GRID_PRODUCTS_COUNT,
  DEFAULT_COLS,
  DEFAULT_PRODUCT_IMAGE_SIZE,
  FilterAndSortButtonStyle,
  MAX_ROWS,
} from './constants';
import {
  Alignments,
  AutoGridTemplateRepeatOptions,
  ButtonWidthType,
  CategoryHeaderLayout,
  CategoryListStrategy,
  ContentJustification,
  GridType,
  HoverType,
  ImageCarouselArrowSize,
  ImageModeId,
  ImagePlacements,
  ImagePositions,
  ImageRatioId,
  LoadMoreType,
  PaginationType,
  RibbonPlacementId,
  RibbonType,
  VerticalAlignments,
} from './types/galleryTypes';
import {GalleryViewMode} from './wishlist/constants';

export type IStylesParams = {
  [P in keyof StylesParamKeys]: IStyleParam<StylesParamKeys[P]>;
};

export type IStylesParamsValues = {
  [P in keyof StylesParamKeys]: IStyleParamValuesByType[StylesParamKeys[P]];
};

export type StylesParamKeys = {
  full_width: StyleParamType.Boolean;
  showAlternativeImage: StyleParamType.Boolean;
  galleryFiltersCategories: StyleParamType.Boolean;
  galleryFiltersPrice: StyleParamType.Boolean;
  galleryFiltersProductOptions: StyleParamType.Boolean;
  galleryShowFilters: StyleParamType.Boolean;
  galleryShowSort: StyleParamType.Boolean;
  gallerySortNameAsc: StyleParamType.Boolean;
  gallerySortNameDes: StyleParamType.Boolean;
  gallerySortNewest: StyleParamType.Boolean;
  gallerySortPriceAsc: StyleParamType.Boolean;
  gallerySortPriceDes: StyleParamType.Boolean;
  gallery_showAddToCartButton: StyleParamType.Boolean;
  'mobile:gallery_showAddToCartButton': StyleParamType.Boolean;
  gallery_addToCartButtonShowOnHover: StyleParamType.Boolean;
  gallery_alternateImagePosition: StyleParamType.Boolean;
  'mobile:gallery_alternateImagePosition': StyleParamType.Boolean;
  gallery_showAddToCartQuantity: StyleParamType.Boolean;
  'mobile:gallery_showQuantity': StyleParamType.Boolean;
  gallery_showDividers: StyleParamType.Boolean;
  gallery_showPrice: StyleParamType.Boolean;
  gallery_showRibbon: StyleParamType.Boolean;
  gallery_showDiscountName: StyleParamType.Boolean;
  gallery_showProductName: StyleParamType.Boolean;
  gallery_showTitle: StyleParamType.Boolean;
  gallery_paginationFirstLastArrows: StyleParamType.Boolean;
  gallery_showProductOptionsButton: StyleParamType.Boolean;
  'mobile:gallery_showProductOptions': StyleParamType.Boolean;
  showQuickView: StyleParamType.Boolean;
  responsive: StyleParamType.Boolean;
  gallery_showCategoryHeaderSection: StyleParamType.Boolean;
  gallery_showCategoryHeaderImage: StyleParamType.Boolean;
  gallery_showCategoryHeaderName: StyleParamType.Boolean;
  gallery_showCategoryHeaderDescription: StyleParamType.Boolean;
  gallery_showCategoryHeaderReadMoreLink: StyleParamType.Boolean;
  gallery_showCategoriesProductsCounter: StyleParamType.Boolean;
  gallery_showCategoriesTitle: StyleParamType.Boolean;
  gallery_showFiltersTitle: StyleParamType.Boolean;

  gallery_showAppliedFilters: StyleParamType.Boolean;
  gallery_appliedFiltersTextFont: StyleParamType.Font;

  galleryColumns: StyleParamType.Number;
  galleryRows: StyleParamType.Number;
  galleryMargin: StyleParamType.Number;
  galleryMarginRow: StyleParamType.Number;
  galleryMarginColumn: StyleParamType.Number;
  'mobile:galleryMarginRow': StyleParamType.Number;
  'mobile:galleryMarginColumn': StyleParamType.Number;
  gallery_productMargin: StyleParamType.Number;
  'mobile:gallery_productMargin': StyleParamType.Number;
  gallery_imageMode: StyleParamType.Number;
  'mobile:gallery_imageMode': StyleParamType.Number;
  gallery_ribbonPlacement: StyleParamType.Number;
  gallery_ribbonType: StyleParamType.Number;
  galleryImageRatio: StyleParamType.Number;
  'mobile:galleryImageRatio': StyleParamType.Number;
  'mobile:galleryMargin': StyleParamType.Number;
  'mobile:galleryColumns': StyleParamType.Number;
  gallery_addToCartAction: StyleParamType.Number;
  gallery_productSize: StyleParamType.Number;
  gallery_productsCount: StyleParamType.Number;
  gallery_gapSize: StyleParamType.Number;
  gallery_gapSizeColumn: StyleParamType.Number;
  gallery_gapSizeRow: StyleParamType.Number;
  gallery_gridType: StyleParamType.Number;
  gallery_loadMoreProductsType: StyleParamType.Number;
  gallery_paginationFormat: StyleParamType.Number;
  'mobile:gallery_productNameFontSize': StyleParamType.Number;
  'mobile:gallery_productPriceFontSize': StyleParamType.Number;
  'mobile:gallery_buttonTextFontSize': StyleParamType.Number;
  'mobile:gallery_ribbonTextFontSize': StyleParamType.Number;
  'mobile:gallery_discountNameFontSize': StyleParamType.Number;
  gallery_imageWidth: StyleParamType.Number;
  'mobile:gallery_imageWidth': StyleParamType.Number;
  gallery_imageAndInfoSpacing: StyleParamType.Number;
  'mobile:gallery_imageAndInfoSpacing': StyleParamType.Number;
  gallery_productOptionsShowOptions: StyleParamType.Number;
  gallery_itemLinkVerticalCustomizePadding: StyleParamType.Number;
  gallery_itemLinkHorizontalCustomizePadding: StyleParamType.Number;
  'mobile:gallery_itemLinkVerticalCustomizePadding': StyleParamType.Number;
  'mobile:gallery_itemLinkHorizontalCustomizePadding': StyleParamType.Number;
  gallery_categoryHeaderSpaceBetweenTitleDescription: StyleParamType.Number;
  gallery_categoryHeaderImageCornerRadius: StyleParamType.Number;
  gallery_categoryDescriptionContainerCornerRadius: StyleParamType.Number;
  gallery_categoryHeaderLayout: StyleParamType.Number;
  gallery_categoryHeaderImageMinHeight: StyleParamType.Number;
  gallery_categoryDescriptionContainerBorderWidth: StyleParamType.Number;

  gallery_hoverType: StyleParamType.Font;
  'mobile:gallery_imageEffect': StyleParamType.Font;
  gallery_imageCarouselArrowSize: StyleParamType.Font;
  gallery_paginationAlignment: StyleParamType.Font;
  gallery_verticalAlignment: StyleParamType.Font;
  gallery_imagePlacement: StyleParamType.Font;
  'mobile:gallery_imagePlacement': StyleParamType.Font;
  gallery_imagePosition: StyleParamType.Font;
  'mobile:gallery_imagePosition': StyleParamType.Font;
  gallery_alignment: StyleParamType.Font;
  gallery_categoryInfoAlignment: StyleParamType.Font;
  gallery_addToCartButtonWidth: StyleParamType.Font;
  gallery_categoriesBreadcrumbsFont: StyleParamType.Font;
  gallery_showCategoriesBreadcrumbs: StyleParamType.Boolean;
  gallery_categoryProductCounterFont: StyleParamType.Font;
  gallery_categoriesBreadcrumbsColor: StyleParamType.Color;
  gallery_categoryNameFont: StyleParamType.Font;
  gallery_categoryDescriptionFont: StyleParamType.Font;
  gallery_categoryDescriptionReadMoreLinkFont: StyleParamType.Font;
  gallery_autoGridTemplateRepeatOption: StyleParamType.Font;
  gallery_categoryProductCounterColor: StyleParamType.Color;
  gallery_categoryNameColor: StyleParamType.Color;
  gallery_categoryDescriptionColor: StyleParamType.Color;
  gallery_categoryDescriptionReadMoreLinkColor: StyleParamType.Color;
  gallery_categoryDescriptionContainerColor: StyleParamType.Color;
  gallery_categoryDescriptionContainerBorderColor: StyleParamType.Color;

  gallery_categoryTreeTitleFont: StyleParamType.Font;
  gallery_categoryTreeTitleColor: StyleParamType.Color;
  gallery_categoryAndFilterNamesFont: StyleParamType.Font;
  gallery_categoryAndFilterNamesColor: StyleParamType.Color;
  gallery_filterOptionsFont: StyleParamType.Font;
  gallery_filterOptionsColor: StyleParamType.Color;
  gallery_selectedCategoryFont: StyleParamType.Font;
  gallery_selectedCategoryColor: StyleParamType.Color;
  gallery_categoryHoverFont: StyleParamType.Font;
  gallery_categoryHoverColor: StyleParamType.Color;
  gallery_clearFiltersButtonFont: StyleParamType.Font;
  gallery_clearFiltersButtonColor: StyleParamType.Color;
  gallery_sortingLabelFont: StyleParamType.Font;
  gallery_sortingLabelColor: StyleParamType.Color;

  gallery_sortingDefaultOption: StyleParamType.Font;
  gallerySortRecommended: StyleParamType.Boolean;
  gallery_showCategories: StyleParamType.Boolean;
  gallery_categoryListStrategy: StyleParamType.Number;
  'mobile:gallery_categoriesBreadcrumbsFontSize': StyleParamType.Number;
  'mobile:gallery_categoryDescriptionFontSize': StyleParamType.Number;
  'mobile:gallery_categoryReadMoreFontSize': StyleParamType.Number;
  'mobile:gallery_categoryFilterAndSortButtonFontSize': StyleParamType.Number;
  'mobile:gallery_categoryTitleFontSize': StyleParamType.Number;
  gallery_addToCartButtonTextFont: StyleParamType.Font;
  gallery_ribbonTopPadding: StyleParamType.Number;
  gallery_ribbonSidePadding: StyleParamType.Number;
  gallery_categoryMobileFilterAndSortButtonStyle: StyleParamType.Number;
  gallery_sortingAndFiltersFillColor: StyleParamType.Color;
  gallery_sortingAndFiltersButtonBorderWidth: StyleParamType.Number;
  gallery_sortingAndFiltersButtonBorderColor: StyleParamType.Color;
  gallery_sortingAndFiltersButtonRadius: StyleParamType.Number;
  gallery_categoriesFilterAndSortButtonTextFont: StyleParamType.Font;
  gallery_categoriesFilterAndSortButtonTextColor: StyleParamType.Color;
  gallery_editorViewMode: StyleParamType.Number;
};

const responsive = createStylesParam('responsive', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({isEditorX}) => isEditorX,
});

const showAlternativeImage = createStylesParam('showAlternativeImage', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

const gallery_showProductOptionsButton = createStylesParam('gallery_showProductOptionsButton', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const galleryColumnsDefaultValue = ({dimensions}) => {
  if (!dimensions) {
    return;
  }

  if (typeof dimensions.width !== 'number') {
    return DEFAULT_COLS;
  }
  const componentWidth: number = dimensions.width;
  const PRODUCT_ITEM_MIN_WIDTH: number = 250;
  const defaultColumns = Math.round(componentWidth / PRODUCT_ITEM_MIN_WIDTH);
  return Math.min(defaultColumns, DEFAULT_COLS);
};

const galleryColumns = createStylesParam('galleryColumns', {
  type: StyleParamType.Number,
  getDefaultValue: galleryColumnsDefaultValue,
});

export const galleryRowsDefaultValue = ({getStyleParamValue}) => {
  const columnsNumber = getStyleParamValue(galleryColumns);
  if (!columnsNumber) {
    return;
  }
  const LEGACY_MAX_ITEM_PER_PAGE = 20;
  return Math.min(Math.floor(LEGACY_MAX_ITEM_PER_PAGE / columnsNumber), MAX_ROWS / 2);
};

const galleryMargin = createStylesParam('galleryMargin', {
  type: StyleParamType.Number,
  getDefaultValue: () => 10,
});

const gallery_productMargin = createStylesParam('gallery_productMargin', {
  type: StyleParamType.Number,
});

const gallery_gapSize = createStylesParam('gallery_gapSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
});

const gallery_imageMode = createStylesParam('gallery_imageMode', {
  type: StyleParamType.Number,
  getDefaultValue: () => ImageModeId.Crop,
});

const galleryImageRatio = createStylesParam('galleryImageRatio', {
  type: StyleParamType.Number,
  getDefaultValue: () => ImageRatioId._1x1,
});

const gallery_imageWidth = createStylesParam('gallery_imageWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 50,
});

const gallery_imageAndInfoSpacing = createStylesParam('gallery_imageAndInfoSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

const gallery_alternateImagePosition = createStylesParam('gallery_alternateImagePosition', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

const gallery_showAddToCartQuantity = createStylesParam('gallery_showAddToCartQuantity', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

const gallery_showAddToCartButton = createStylesParam('gallery_showAddToCartButton', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

const gallery_titleFontStyle = createStylesParam('gallery_titleFontStyle', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 16,
  }),
});

const gallery_priceFontStyle = createStylesParam('gallery_priceFontStyle', {
  type: StyleParamType.Font,
});

const gallery_discountNameFont = createStylesParam('gallery_discountNameFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 14,
  }),
});

const gallery_addToCartButtonTextFont = createStylesParam('gallery_addToCartButtonTextFont', {
  type: StyleParamType.Font,
});

const gallery_ribbonTextFont = createStylesParam('gallery_ribbonTextFont', {
  type: StyleParamType.Font,
});

const gallery_hoverType = createStylesParam('gallery_hoverType', {
  type: StyleParamType.Font,
  getDefaultValue: ({getStyleParamValue}) => ({
    fontStyleParam: false,
    value: getStyleParamValue(showAlternativeImage) ? HoverType.Alternate : HoverType.None,
  }),
});

const gallery_categoryHeaderLayout = createStylesParam('gallery_categoryHeaderLayout', {
  type: StyleParamType.Number,
  getDefaultValue: () => CategoryHeaderLayout.TextBelow,
});

const gallery_imagePosition = createStylesParam('gallery_imagePosition', {
  type: StyleParamType.Font,
  getDefaultValue: () => ({
    fontStyleParam: false,
    value: ImagePositions.LEFT,
  }),
});

const gallery_imagePlacement = createStylesParam('gallery_imagePlacement', {
  type: StyleParamType.Font,
  getDefaultValue: () => ({
    fontStyleParam: false,
    value: ImagePlacements.VERTICAL,
  }),
});

const gallery_itemLinkVerticalCustomizePadding = createStylesParam('gallery_itemLinkVerticalCustomizePadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

const gallery_itemLinkHorizontalCustomizePadding = createStylesParam('gallery_itemLinkHorizontalCustomizePadding', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

const mobileGallery_itemLinkVerticalCustomizePadding = createStylesParam(
  'mobile:gallery_itemLinkVerticalCustomizePadding',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }
);

const mobileGallery_itemLinkHorizontalCustomizePadding = createStylesParam(
  'mobile:gallery_itemLinkHorizontalCustomizePadding',
  {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }
);

const gallery_categoriesBreadcrumbsFont = createStylesParam('gallery_categoriesBreadcrumbsFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 16,
  }),
});

const gallery_categoryNameFont = createStylesParam('gallery_categoryNameFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Heading-M', {
    size: 28,
  }),
});

const gallery_categoryDescriptionFont = createStylesParam('gallery_categoryDescriptionFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 16,
  }),
});

const gallery_categoryDescriptionReadMoreLinkFont = createStylesParam('gallery_categoryDescriptionReadMoreLinkFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 16,
  }),
});

const gallery_filterOptionsFont = createStylesParam('gallery_filterOptionsFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 14,
  }),
});

export const baseStylesParams: IStylesParams = {
  gallery_addToCartButtonTextFont,
  full_width: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showAlternativeImage,
  galleryFiltersCategories: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryFiltersPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryFiltersProductOptions: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  galleryShowSort: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallerySortNameAsc: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortNameDes: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortNewest: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortPriceAsc: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortPriceDes: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortRecommended: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showAddToCartButton,
  'mobile:gallery_showAddToCartButton': {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_showAddToCartButton),
  },
  gallery_addToCartButtonShowOnHover: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_alternateImagePosition,
  'mobile:gallery_alternateImagePosition': {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_alternateImagePosition),
  },
  gallery_showAddToCartQuantity,
  'mobile:gallery_showQuantity': {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_showAddToCartQuantity),
  },
  gallery_showDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showDiscountName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_ribbonSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_ribbonTopPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_showProductName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  gallery_showCategoryHeaderSection: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoryHeaderImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({isMobile}) => !isMobile,
  },
  gallery_showCategoryHeaderReadMoreLink: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoryHeaderName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoryHeaderDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({isMobile}) => !isMobile,
  },
  gallery_paginationFirstLastArrows: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showProductOptionsButton,
  'mobile:gallery_showProductOptions': {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_showProductOptionsButton),
  },
  showQuickView: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  responsive,
  galleryColumns,
  galleryRows: {
    type: StyleParamType.Number,
    getDefaultValue: galleryRowsDefaultValue,
  },
  galleryMargin,
  galleryMarginRow: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryMargin),
  },
  galleryMarginColumn: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryMargin),
  },
  'mobile:galleryMarginRow': {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  'mobile:galleryMarginColumn': {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gallery_productMargin,
  'mobile:gallery_productMargin': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_productMargin) ?? 0,
  },
  gallery_imageMode,
  'mobile:gallery_imageMode': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageMode),
  },
  gallery_ribbonPlacement: {
    type: StyleParamType.Number,
    getDefaultValue: () => RibbonPlacementId.OnImage,
  },
  gallery_ribbonType: {
    type: StyleParamType.Number,
    getDefaultValue: () => RibbonType.RECTANGLE,
  },
  galleryImageRatio,
  'mobile:galleryImageRatio': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryImageRatio),
  },
  'mobile:galleryMargin': {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  'mobile:galleryColumns': {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  gallery_addToCartAction: {
    type: StyleParamType.Number,
    getDefaultValue: () => AddToCartActionOption.MINI_CART,
  },
  gallery_productSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_PRODUCT_IMAGE_SIZE,
  },
  gallery_productsCount: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_AUTO_GRID_PRODUCTS_COUNT,
  },
  gallery_gapSize,
  gallery_gapSizeColumn: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_gapSize),
  },
  gallery_gapSizeRow: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_gapSize),
  },
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => (getStyleParamValue(responsive) ? GridType.AUTO : GridType.MANUAL),
  },
  gallery_loadMoreProductsType: {
    type: StyleParamType.Number,
    getDefaultValue: () => LoadMoreType.BUTTON,
  },
  gallery_paginationFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => PaginationType.PAGES,
  },
  'mobile:gallery_productNameFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_titleFontStyle)?.size ?? 16,
  },
  'mobile:gallery_productPriceFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_priceFontStyle)?.size ?? 16,
  },
  'mobile:gallery_discountNameFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_discountNameFont)?.size ?? 14,
  },
  'mobile:gallery_buttonTextFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_addToCartButtonTextFont)?.size ?? 15,
  },
  'mobile:gallery_ribbonTextFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_ribbonTextFont)?.size ?? 14,
  },
  gallery_imageWidth,
  'mobile:gallery_imageWidth': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageWidth),
  },
  gallery_imageAndInfoSpacing,
  'mobile:gallery_imageAndInfoSpacing': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageAndInfoSpacing),
  },
  gallery_productOptionsShowOptions: {
    type: StyleParamType.Number,
  },
  gallery_itemLinkVerticalCustomizePadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_itemLinkVerticalCustomizePadding),
  },
  gallery_itemLinkHorizontalCustomizePadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_itemLinkHorizontalCustomizePadding),
  },
  'mobile:gallery_itemLinkVerticalCustomizePadding': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(mobileGallery_itemLinkVerticalCustomizePadding),
  },
  'mobile:gallery_itemLinkHorizontalCustomizePadding': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(mobileGallery_itemLinkHorizontalCustomizePadding),
  },
  gallery_categoryHeaderSpaceBetweenTitleDescription: {
    type: StyleParamType.Number,
    getDefaultValue: ({isMobile}) => (isMobile ? 0 : 4),
  },
  gallery_categoryHeaderImageCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_categoryDescriptionContainerCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_categoryHeaderLayout,
  gallery_categoryHeaderImageMinHeight: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({isMobile, getStyleParamValue}) => {
      if (isMobile) {
        return getStyleParamValue(gallery_categoryHeaderLayout) === CategoryHeaderLayout.TextInside ? 304 : 140;
      }
      return 280;
    },
  },
  gallery_categoryDescriptionContainerBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_hoverType,
  'mobile:gallery_imageEffect': {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_hoverType),
  },
  gallery_imageCarouselArrowSize: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ImageCarouselArrowSize.SMALL,
    }),
  },
  gallery_paginationAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ContentJustification.CENTER,
    }),
  },
  gallery_verticalAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: VerticalAlignments.CENTER,
    }),
  },
  gallery_imagePlacement,
  'mobile:gallery_imagePlacement': {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imagePlacement),
  },
  gallery_imagePosition,
  'mobile:gallery_imagePosition': {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imagePosition),
  },
  gallery_autoGridTemplateRepeatOption: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: AutoGridTemplateRepeatOptions.AUTO_FIT,
    }),
  },
  gallery_alignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: Alignments.LEFT,
    }),
  },
  gallery_categoryInfoAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: ({isRTL}) => ({
      fontStyleParam: false,
      value: isRTL ? Alignments.RIGHT : Alignments.LEFT,
    }),
  },
  gallery_categoriesBreadcrumbsFont,
  gallery_showCategoriesBreadcrumbs: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoriesProductsCounter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showAppliedFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_appliedFiltersTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({isMobile}) =>
      wixFontParam('Body-M', {
        size: isMobile ? 12 : 14,
      }) as any,
  },
  gallery_addToCartButtonWidth: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ButtonWidthType.STRETCH,
    }),
  },
  gallery_categoryProductCounterFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  gallery_categoryNameFont,
  gallery_categoryDescriptionFont,
  gallery_categoryDescriptionReadMoreLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_categoryProductCounterColor: {
    type: StyleParamType.Color,
  },
  gallery_categoriesBreadcrumbsColor: {
    type: StyleParamType.Color,
  },
  gallery_categoryNameColor: {
    type: StyleParamType.Color,
  },
  gallery_categoryDescriptionColor: {
    type: StyleParamType.Color,
  },
  gallery_categoryDescriptionReadMoreLinkColor: {
    type: StyleParamType.Color,
  },
  gallery_categoryDescriptionContainerColor: {
    type: StyleParamType.Color,
  },
  gallery_categoryDescriptionContainerBorderColor: {
    type: StyleParamType.Color,
  },
  'mobile:gallery_categoriesBreadcrumbsFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_categoriesBreadcrumbsFont)?.size ?? 16,
  },
  'mobile:gallery_categoryDescriptionFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_categoryDescriptionFont)?.size ?? 16,
  },
  'mobile:gallery_categoryReadMoreFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) =>
      getStyleParamValue(gallery_categoryDescriptionReadMoreLinkFont)?.size ?? 16,
  },
  'mobile:gallery_categoryFilterAndSortButtonFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_filterOptionsFont)?.size ?? 16,
  },
  'mobile:gallery_categoryTitleFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_categoryNameFont)?.size ?? 28,
  },
  gallery_categoryTreeTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  gallery_categoryTreeTitleColor: {type: StyleParamType.Color},
  gallery_categoryAndFilterNamesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_categoryAndFilterNamesColor: {type: StyleParamType.Color},
  gallery_filterOptionsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  gallery_filterOptionsColor: {type: StyleParamType.Color},
  gallery_selectedCategoryFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {
        bold: false,
        italic: false,
        underline: true,
      },
    }),
  },
  gallery_selectedCategoryColor: {type: StyleParamType.Color},
  gallery_categoryHoverFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {
        bold: false,
        italic: false,
        underline: true,
      },
    }),
  },
  gallery_categoryHoverColor: {type: StyleParamType.Color},
  gallery_clearFiltersButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  gallery_clearFiltersButtonColor: {type: StyleParamType.Color},
  gallery_sortingLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_sortingLabelColor: {type: StyleParamType.Color},

  gallery_sortingDefaultOption: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: 'gallerySortRecommended',
    }),
  },
  gallery_categoryMobileFilterAndSortButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => FilterAndSortButtonStyle.TEXT,
  },
  gallery_sortingAndFiltersFillColor: {
    type: StyleParamType.Color,
  },
  gallery_sortingAndFiltersButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  gallery_sortingAndFiltersButtonBorderColor: {
    type: StyleParamType.Color,
  },
  gallery_sortingAndFiltersButtonRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_categoriesFilterAndSortButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_categoriesFilterAndSortButtonTextColor: {
    type: StyleParamType.Color,
  },
  gallery_showCategories: {type: StyleParamType.Boolean, getDefaultValue: () => true},
  gallery_showCategoriesTitle: {type: StyleParamType.Boolean, getDefaultValue: () => true},
  gallery_showFiltersTitle: {type: StyleParamType.Boolean, getDefaultValue: () => true},
  gallery_categoryListStrategy: {
    type: StyleParamType.Number,
    getDefaultValue: () => CategoryListStrategy.AUTOMATICALLY,
  },
  gallery_editorViewMode: {
    type: StyleParamType.Number,
    getDefaultValue: () => GalleryViewMode.EDITOR_EMPTY_STATE,
  },
};
